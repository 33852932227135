import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { saveReportPackageElections, sponsorAppsRequest, sponsorsRequest } from './reportManagementApi';
import { MgmtMap, Sponsor, SponsorCard, ElectionsDTO, ReportPackageDTO, SponsorApplicationDTO } from 'common/Types/ReportMgmtTypes';
import { AsyncStatus } from 'common/Types/StateReferenceTypes';
import { RootState } from 'state/store';
import { UserState } from 'redux-oidc';
import { GatewayState } from 'gatewaySlice';
import configData from 'config.json';
import { appTelemetry } from 'services/TelemetryService';
import { apiReqEvnt, apiReqMsg, apiResEvnt, apiResMsg } from 'common/Utils/telemetryUtil';

export interface ReportManagementState {
  status: AsyncStatus;
  sponsorCards: MgmtMap<SponsorCard>;
}
const initialState: ReportManagementState = {
  status: 'idle',
  sponsorCards: {},
}

export const fetchSponsorApps = createAsyncThunk(
  'reportManagement/sponsorAppRequest',
  async (sponsor: Sponsor, { getState }) => {
    const { oidc, gateway } = getState() as { oidc: UserState, gateway: GatewayState };
    const url = `${configData.ENDPOINT_ALX_APIBASEURL}/report-admin/application/${sponsor.id}`;
    appTelemetry(apiReqEvnt, apiReqMsg(url), oidc, gateway, {
      httpRouteEndpoint: url
    });

    const response = await sponsorAppsRequest(sponsor).then((res) => {
      appTelemetry(apiResEvnt, apiResMsg(url), oidc, gateway, {
        httpRouteEndpoint: url
      });

      return res;
    });

    return response;
  }
)

export const fetchSponsors = createAsyncThunk(
  'reportManagement/sponsorsRequest',
  async (_, { getState }) => {
    const { oidc, gateway } = getState() as { oidc: UserState, gateway: GatewayState };
    const url = `${configData.ENDPOINT_ALX_APIBASEURL}/report-admin/sponsor`;
    appTelemetry(apiReqEvnt, apiReqMsg(url), oidc, gateway, {
      httpRouteEndpoint: url
    });

    const response = await sponsorsRequest().then((res) => {
      appTelemetry(apiResEvnt, apiResMsg(url), oidc, gateway, {
        httpRouteEndpoint: url
      });
      return res;
    });

    return response;
  }
)

export const saveElections = createAsyncThunk(
  'reportManagement/saveElections',
  async (sponsorId: number, { getState }) => {
    const { reportManagement } = getState() as { reportManagement: ReportManagementState, oidc: UserState, gateway: GatewayState };
    const electionList: SponsorApplicationDTO[] = Object.values(reportManagement.sponsorCards[sponsorId]?.pendingReportPackageElections || {});

    const dto: ElectionsDTO = {
      sponsorId,
      electionList
    }
    const response = await saveReportPackageElections(dto);
    return response;
  }
);

export const reportManagementSlice = createSlice({
  name: 'reportManagement',
  initialState,
  reducers: {
    setSelectedRolesforReportPack(state: ReportManagementState, action) {
      const dto: ReportPackageDTO = action.payload;
      const { sponsorId, sponsorAppId, reportPackageId, selectedRoles } = dto;
      if (!state.sponsorCards[sponsorId].pendingReportPackageElections) {
        state.sponsorCards[sponsorId].pendingReportPackageElections = {};
      }

      const app = state.sponsorCards[sponsorId].cardApps[sponsorAppId];
      const existingElection = state.sponsorCards[sponsorId].pendingReportPackageElections[sponsorAppId];
      const report = app.reports.find(r => r.id === reportPackageId);
      if (existingElection) {
        const reportIndex = existingElection.reports.findIndex(r => r.id === reportPackageId);

        if (selectedRoles.length > 0) {
          existingElection.reports[reportIndex].selectedRoles = selectedRoles;
          existingElection.reports[reportIndex].isActive = true;
        } else {
          existingElection.reports[reportIndex].selectedRoles = [];
          existingElection.reports[reportIndex].isActive = false;
        }
      } else {
        if (report) {
          state.sponsorCards[sponsorId].pendingReportPackageElections[sponsorAppId] = {
            ...app,
            reports: app.reports.map(report =>
              report.id === reportPackageId
                ? { ...report, selectedRoles, isActive: (selectedRoles.length > 0 ? true : false) }
                : { ...report, selectedRoles: (report.selectedRoles === null ? [] : report.selectedRoles), isActive: (report.selectedRoles && report.selectedRoles.length > 0 ? true : false) }
            ),

          };
        }
      }
    },
    setSelectedAppRows(state: ReportManagementState, action) {
      const whitelist = action.payload.rows;
      const target = state.sponsorCards[action.payload.sponsorId];
      target.selectedAppRows = whitelist;
      const dynamicdata: any[] = [];
      target.pendingReportPackageElections = Object.fromEntries(
        Object.entries(target.pendingReportPackageElections)
          .filter(([key]) => whitelist.includes(key))
      )

      target.cardApps = Object.fromEntries(
        Object.entries(target.cardApps)
          .map(([key, value]) => {
            if (whitelist.includes(key)) {
              const clone: SponsorApplicationDTO = {
                ...value,
                reports: Object.values(value.reports)
              }
              dynamicdata.push(clone);
            }
            Object.assign(target.pendingReportPackageElections, dynamicdata);
            return [key, value];

          })
      )
    },
    clearSavedReportPack(state: ReportManagementState, action: PayloadAction<number>) {
      const sponsorId = action.payload;
      state.sponsorCards[sponsorId].pendingReportPackageElections = {}

    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSponsorApps.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSponsorApps.fulfilled, (state, action) => {
        state.status = 'idle';
        state.sponsorCards[action.payload.cardSponsor.id] = action.payload;
      })
      .addCase(fetchSponsorApps.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchSponsors.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSponsors.fulfilled, (state, action) => {
        state.status = 'idle';
        state.sponsorCards = action.payload;
      })
      .addCase(fetchSponsors.rejected, (state) => {
        state.status = 'failed';
      })
  }
});

export const { setSelectedRolesforReportPack, setSelectedAppRows, clearSavedReportPack } = reportManagementSlice.actions;

export const selectSponsorCards = (state: RootState) => state.reportManagement.sponsorCards;
export const selectAsyncStatus = (state: RootState) => state.reportManagement.status;

export default reportManagementSlice.reducer;