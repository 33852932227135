import React, { useState, useEffect, useCallback, MouseEvent } from 'react';
import {
  Card, CardContent, Box, Accordion, AccordionSummary, AccordionDetails, Typography,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Divider,
  FormControl, Select, MenuItem, ListItemText, Tooltip, Checkbox
} from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useDispatch } from 'react-redux';
import { ThunkDispatch, unwrapResult } from '@reduxjs/toolkit';
import { setSelectedRolesforReportPack, fetchSponsorApps, saveElections, clearSavedReportPack } from '../reportManagementSlice';
import { SponsorCard, MgmtMap, SponsorAppRowModel, ReportPackageDTO } from '../../../common/Types/ReportMgmtTypes';
import styles from './reportsCard.module.scss';
import CustomFooter from './CustomFooter';
import CustomChip from './CustomChip';
import { useNavigationPrompt } from './useNavigationPrompt';
import { RootState } from 'state/store';
import CenteredCircularProgress from 'components/commonComponent/CenteredCircularProgress';

export interface SponsorCardProps {
  card: SponsorCard;
}

const generateReportKey = (studyId: string, reportId: string) => `${studyId}_${reportId}`;

export const ReportsCard = ({ card }: SponsorCardProps) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, void, any>>();
  const [expanded, setExpanded] = useState<boolean>(false);
  const [gridData, setGridData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedRoles, setSelectedRoles] = useState<Record<string, string[]>>({});
  const [availableRoles, setAvailableRoles] = useState<Record<string, string[]>>({});
  const [reportNames, setReportNames] = useState<string[]>([]);
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState<boolean>(false); // State for success dialog
  const { showDialog, confirmNavigation, cancelNavigation } = useNavigationPrompt(isEdited);

  const transformData = useCallback((applications: MgmtMap<SponsorAppRowModel>) => {
    const reportNamesSet = new Set<string>();
    const newSelectedRoles: Record<string, string[]> = {};

    const transformedRows = Object.values(applications).map((app, index) => {
      const rowData: any = {
        id: index + 1,
        studyName: app.name,
      };

      app.reports.forEach((report) => {
        const reportKey = generateReportKey(app.applicationId, report.id);
        const selectedRolesForReport = selectedRoles[reportKey] || report.selectedRoles || [];

        newSelectedRoles[reportKey] = selectedRolesForReport;
        rowData[report.displayName] = selectedRolesForReport;
        reportNamesSet.add(report.displayName);

        setAvailableRoles(prevAvailableRoles => ({
          ...prevAvailableRoles,
          [reportKey]: report.availableRoles,
        }));
      });

      return rowData;
    });

    setGridData(transformedRows);
    setReportNames(Array.from(reportNamesSet));
    setSelectedRoles(newSelectedRoles);
  }, [selectedRoles]);

  const getData = async () => {
      try {
        const action = await dispatch(fetchSponsorApps(card.cardSponsor));
        const response = unwrapResult(action);

        if (!response || !response.cardApps || !Object.values(response.cardApps).length) {
          throw new Error('No data received');
        }

        transformData(response.cardApps);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setError('Failed to fetch data');
        console.error('Error fetching sponsor apps:', err);
      }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (expanded && Object.keys(card.cardApps).length === 0) {
        setLoading(true);
        setError(null);
        getData();
      } else if (expanded && Object.keys(card.cardApps).length > 0) {
        transformData(card.cardApps);
      }
    };
    fetchData();
  }, [expanded, card.cardApps, card.cardSponsor]);

  const handleAccordionToggle = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const handleSelectionChange = useCallback(
    (reportKey: string, appName: string, sponsorName: string, value: string[]) => {
      setSelectedRoles((prevRoles) => ({
        ...prevRoles,
        [reportKey]: value,
      }));

      const [applicationId, reportId] = reportKey.split('_');
      const applicationKey = `${applicationId}_${sponsorName}_${appName}`;

      const dto: ReportPackageDTO = {
        sponsorAppId: applicationKey,
        reportPackageId: reportId,
        selectedRoles: value,
        sponsorId: card.cardSponsor.id,
      };

      dispatch(setSelectedRolesforReportPack(dto));
      setIsEdited(true); // Mark as edited
    },
    [dispatch, card.cardSponsor.id]
  );

  const handleSelectAll = useCallback((reportKey: string, appName: string, sponsorName: string, roles: string[], isChecked: boolean) => {
    const newRoles = isChecked ? roles : [];
    handleSelectionChange(reportKey, appName, sponsorName, newRoles);
  }, [handleSelectionChange]);

  const handleRoleChange = useCallback((reportKey: string, role: string, isChecked: boolean) => {
    setSelectedRoles((prevRoles) => {
      const currentRoles = prevRoles[reportKey] || [];
      const newRoles = isChecked
        ? [...currentRoles, role].filter((v, i, a) => a.indexOf(v) === i)
        : currentRoles.filter((r) => r !== role);
      return {
        ...prevRoles,
        [reportKey]: newRoles,
      };
    });
  }, []);

  const handleRoleRemove = (reportKey: string, appName: string, sponsorName: string, role: string, event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setIsEdited(true);
    setSelectedRoles((prevRoles) => {
      const currentRoles = prevRoles[reportKey] || [];
      const newRoles = currentRoles.filter((r) => r !== role);
      const [applicationId, reportId] = reportKey.split('_');
      const applicationKey = `${applicationId}_${sponsorName}_${appName}`;

      const dto: ReportPackageDTO = {
        sponsorAppId: applicationKey,
        reportPackageId: reportId,
        selectedRoles: newRoles,
        sponsorId: card.cardSponsor.id,
      };

      dispatch(setSelectedRolesforReportPack(dto));
      return {
        ...prevRoles,
        [reportKey]: newRoles,
      };
    });
  };

  const renderValue = (reportKey: string, appName: string, sponsorName: string, selected: string[], availableRoles: string[]) => {
    if (selected.length === availableRoles.length) {
      return (
        <CustomChip
          label="All Roles"
          onDelete={(event) => {
            event.stopPropagation();
            handleSelectAll(reportKey, appName, sponsorName, availableRoles, false);
          }}
        />
      );
    }

    const displayValue = selected.join(', ');

    return (
      <Tooltip title={displayValue} arrow>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {selected.map((value, index) => (
            <CustomChip
              key={index}
              label={value}
              onDelete={(event) => handleRoleRemove(reportKey, appName, sponsorName, value, event)}
            />
          ))}
        </Box>
      </Tooltip>
    );
  };

  const renderReportCell = (reportName: string) => {
    return (params: GridRenderCellParams) => {
      const app = Object.values(card.cardApps)[params.row.id - 1];
      const report = app?.reports.find(r => r.displayName === reportName);

      if (!report) return null;

      const reportKey = generateReportKey(app.applicationId, report.id);
      const selected = selectedRoles[reportKey] || [];
      const availableRolesForReport = availableRoles[reportKey] || [];

      return (
        <FormControl variant="standard" fullWidth>
          <Select
            multiple
            value={selected}
            renderValue={(selected) => renderValue(reportKey, app.name, app.sponsorName, selected, availableRolesForReport)}
            onChange={(event) => handleSelectionChange(reportKey, app.name, app.sponsorName, event.target.value as string[])}
            className={styles.dropdown}
            MenuProps={{
              PaperProps: {
                className: styles.scrollable_dropdown,
                style: {
                  maxHeight: 200,
                  width: 250,
                },
              },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
            autoFocus={false}
            onClick={(event) => event.stopPropagation()}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#00aebd',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#00aebd',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#00aebd',
                },
              },
              '& .MuiInputBase-root': {
                '&:after': {
                  borderBottomColor: '#00aebd',
                },
              },
            }}
          >
            <MenuItem key="select-all" value="All Roles">
              <Checkbox
                checked={selected.length === availableRolesForReport.length}
                onChange={(event) => {
                  handleSelectAll(reportKey, app.name, app.sponsorName, availableRolesForReport, event.target.checked);
                }}
                sx={{
                  '&.Mui-checked': {
                    color: '#00aebd',
                  },
                }}
              />
              <ListItemText primary="Select All"
                onClick={(event) => {
                  event.stopPropagation();
                  handleSelectAll(reportKey, app.name, app.sponsorName, availableRolesForReport, selected.length !== availableRolesForReport.length);
                }} />
            </MenuItem>
            {availableRolesForReport.map((role) => (
              <MenuItem key={role} value={role}>
                <Checkbox
                  checked={selected.includes(role)}
                  onChange={(event) => {
                    handleRoleChange(reportKey, role, event.target.checked);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#00aebd',
                    },
                  }}
                />
                <ListItemText primary={role} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    };
  };

  const handleSave = () => {
    dispatch(saveElections(card.cardSponsor.id))
      .unwrap()
      .then(() => {
        getData();
        setIsEdited(false);
        dispatch(clearSavedReportPack(card.cardSponsor.id));
        setShowSuccessDialog(true);
      })
      .catch((error) => {
        console.error('Save failed:', error);
      });
  };

  const handleCloseSuccessDialog = () => {
    setShowSuccessDialog(false);
  };

  const columns: GridColDef[] = [
    { field: 'studyName', headerName: 'Study Names', flex: 2 },
    ...reportNames.map(reportName => ({
      field: reportName,
      headerName: reportName,
      flex: 2,
      renderCell: renderReportCell(reportName),
    }))
  ];

  return (
    <Card key={`${card.cardSponsor.name}_${card.cardSponsor.id}`} className={styles.card}>
      <Accordion expanded={expanded} onChange={handleAccordionToggle}>
        <AccordionSummary
          aria-controls={`panel-content-${card.cardSponsor.id}`}
          id={`panel-header-${card.cardSponsor.id}`}
        >
          <CardContent className={styles.cardHeader}>
            <Box className={styles.headerLabel}>
              {card.cardSponsor.name}
            </Box>
          </CardContent>
        </AccordionSummary>
        <Divider variant="middle" className={expanded ? styles.divider : styles.unflagged} />
        <AccordionDetails>
          {expanded && (
            <div className={styles.data_grid_container}>
              {loading ? (
                <CenteredCircularProgress />
              ) : error ? (
                <Typography color="error">{error}</Typography>
              ) : (
                <>
                  <DataGrid
                    sx={{
                      border: 0,
                      "& .MuiDataGrid-cell": {
                        border: 0,
                      },
                      "& .MuiDataGrid-columnHeaderTitle": {
                        fontSize: "16px",
                        fontWeight: "bold",
                      },
                      "& .MuiDataGrid-columnSeparator": {
                        visibility: "hidden",
                      },
                    }}
                    rows={gridData}
                    columns={columns}
                    disableSelectionOnClick
                    autoHeight
                    pageSize={15}
                    rowsPerPageOptions={[15]}
                    pagination
                    components={{
                      Footer: () => <CustomFooter onSave={handleSave} saveDisabled={!isEdited} />,
                    }}
                    className={styles.data_grid}
                  />
                </>
              )}
            </div>
          )}
        </AccordionDetails>
      </Accordion>

      <Dialog
        open={showDialog}
        onClose={cancelNavigation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Unsaved Changes</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have unsaved changes. Are you sure you want to leave this page?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelNavigation} style={{ background: '#00aebd', color: 'white',textTransform: 'none' }}autoFocus>
            Cancel
          </Button>
          <Button onClick={confirmNavigation} style={{ background: '#00aebd', color: 'white',textTransform: 'none'}} autoFocus>
            Discard
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showSuccessDialog}
        onClose={handleCloseSuccessDialog}
        aria-labelledby="success-dialog-title"
        aria-describedby="success-dialog-description"
      >
        <DialogTitle id="success-dialog-title">Success</DialogTitle>
        <DialogContent>
          <DialogContentText id="success-dialog-description">
            Data saved successfully!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSuccessDialog} style={{ background: '#00aebd', color: 'white',textTransform: 'none' }} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};
